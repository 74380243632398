import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const formOfPaymentComputed = {
    ...mapState('formofpayment', {
        formofpayment:(state) => state.form_of_payments,
    }),
    ...mapGetters('formofpayment', ['getFormOfPayment'])
}

// export property actions
export const formOfPaymentMethods = mapActions('formofpayment', ['allFormOfPayment','createFormOfPayment',
 'updateFormOfPayment', 'deleteFormOfPayment',
])
