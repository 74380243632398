import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const propertyComputed = {
    ...mapState('property', {
        properties:(state) => state.properties,
    }),
    ...mapGetters('property', ['getProperties'])
}

// export property actions
export const propertyMethods = mapActions('property', ['allProperty',
'allPropertyByLeaseStartDateAndLeaseEndDate', 'allPropertyByAvailabilityDate','allPropertyByPropertyOwnerWithStatus','allPropertyByStatus','allPropertyByPropertyType',
'showProperty', 'createProperty', 'updateProperty', 'deleteProperty', 'deleteAmenity',
 'deleteParkingType', 'deletePropertyType', 'deleteUtility', 'updateAmenity', 'createAmenity',
'createParkingType','updateParkingType','createPropertyType','updatePropertyType','createUtility','createPropertyGallery', 'orderPropertyGallery', 'deletePropertyGallery', 'updateUtility',
])
